<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>退费统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                  <a-col :lg="24" :xl="24">
                      <!-- <financeTotal :loading='loading'  :leads_data ='leads_data'/> -->
                      <VElines :loading='loading' title="退费统计"  :item='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                  </a-col>
                  <a-col :lg="24" :xl="24">
                    <LRanking title="课程顾问退费" :rank_data="consultant_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                  </a-col>
                  <a-col :lg="24" :xl="12">
                    <LRanking title="校区退费" :rank_data="studio_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                  </a-col>
                  <a-col :lg="24" :xl="12">
                    <LRanking title="渠道退费" :rank_data="channel_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                  </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import financeTotal from './financeTotal'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'return',
  components:{
    financeTotal,
    statistics
  },
  data() {
    return {
      loading:false,
      leads_data:{},
      channel_data:{},
      studio_data:{},
      consultant_data:{},
      counts:[],
      studios:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
    //   this.getCount(obj)
      let res = await this.$store.dispatch('analysisFinanceRefundAction', obj)
      this.leads_data = res.data.summate
      this.channel_data = res.data.channel
      this.studio_data = res.data.studio
      this.consultant_data = res.data.consultant
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisFinanceCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>

